import getConfig from 'next/config'
import isEmpty from 'lodash/isEmpty'
import { env } from 'next-runtime-env'

/**
 * Gets the current public runtime config of next app
 * @param {String} envKeyName @optional name of the config
 * @returns {Object | String | undefined} value of the specific config if exist or all configs as an object
 * @returns {undefined} value if requested envKeyName doesn't exist
 * @caveats always test if its undefined when asking for specific envKeyName when unsure if that may not be present in process.env
 */
export const getRuntimeEnv = (
  envKeyName: string = ''
): Record<string, string> | string | undefined => {
  const { publicRuntimeConfig = {} } = getConfig() || {}

  if (isEmpty(publicRuntimeConfig)) {
    /* we are using app router */
    return env(envKeyName) || ''
  }

  if (isEmpty(envKeyName)) {
    return publicRuntimeConfig
  }

  return publicRuntimeConfig?.[envKeyName] // returns undefined if not found when asked for specific
}

export const getRuntimeEnvKeys = (keys: string[]): Record<string, string> => {
  const { publicRuntimeConfig = {} } = getConfig() || {}
  const requiredEnvProps: Record<string, string> = {}
  if (isEmpty(publicRuntimeConfig)) {
    /* we are using app router */
    keys.forEach((key) => (requiredEnvProps[key] = env(key) ?? ''))
    return requiredEnvProps
  }

  keys.forEach((key) => (requiredEnvProps[key] = publicRuntimeConfig[key] ?? ''))
  return requiredEnvProps
}
