import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

export const TrackJS =
  typeof window !== 'undefined' ? require('trackjs').TrackJS : require('trackjs-node').TrackJS

import type { TrackJSErrorPayload } from 'trackjs'

const version = '5.0.9'

export function TrackJSInstall() {
  if (!TrackJS.isInstalled()) {
    TrackJS.install({
      token: '477bc86de9db427fb9142351004b85f3',
      application: 'grille-' + getRuntimeEnv('NEXT_PUBLIC_DEPLOY_ENV'),
      version: version,
      onError: function (payload: TrackJSErrorPayload) {
        payload.network = payload.network.filter(function (item) {
          return item.url.match(/doubleclick\.net|clarity\.ms|openx\.net/) == null
        })
        return true
      }
    })
  }
}
