'use client'

import logger from '@grille/utils/logger'
import { useEffect } from 'react'
import { TrackJS, TrackJSInstall } from './trackjs-loader'

TrackJSInstall()

export default function GlobalError({ error }: { readonly error: Error & { digest?: string } }) {
  logger.error(`[Page error] - global-error.tsx [message:${JSON.stringify(error)}]`)

  useEffect(() => {
    TrackJS.track(error)
  }, [error])

  return (
    // global-error must include html and body tags
    <html lang='en'>
      <body>
        <div
          className='h-screen relative bg-cover bg-no-repeat bg-center text-white overflow-hidden'
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/ship.jpeg')"
          }}
        >
          <div
            style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            className='absolute'
          >
            <h1>{500}</h1>
            <p>An unexpected error has occurred.</p>

            <a href='/' className='flex w-full flex-col item-stretch mt-2 text-white'>
              <span className='btn white last:my-0'>Go Back</span>
            </a>
          </div>
        </div>
      </body>
    </html>
  )
}
